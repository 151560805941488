import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import config from '../../../config';

import React, { useEffect, useState, useRef } from 'react';
import { useStore, useDispatch, useSelector } from 'react-redux'
import { useIndicesQuery } from '../../../store/indexOneApi';
import Typography from '@mui/material/Typography';


import { Link } from 'react-router-dom'
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import { DataGrid } from '@mui/x-data-grid';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Skeleton from '@mui/material/Skeleton';
import Tooltip from '@mui/material/Tooltip';
import StageChip from '../../elements/StageChip'
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Chip from '@mui/material/Chip'


import Tornado from '../../elements/indices/Tornado'
import Scatter from '../../elements/indices/Scatter'
import MultiLine from '../../elements/indices/MultiLine'
import IndicesTable from '../../elements/indices/IndicesTable'
import FilterAccordion from '../../elements/indices/FilterAccordion'
import IndexOverviewPanel from '../../elements/indices/IndexOverviewPanel'

import {mapping} from '../../elements/indices/FilterConfig'
import snakeToTitleCase from '../../helpers/snakeToTitleCase';


/*

new indices shall have a tags object to identify common characteristics
tags = {
  "is_core":True,
  "country":"UK",
  "factor":"value"
}
*/

function returnTooltip(cat){
  const cats = {
    "absolutePerformance":"Index returns over the past 30 days",
    "normalizedPerformance":`Normalized index returns over the past 30 days.
     The green and red lines represent the top and bottom performing index respectively.`,
     "sharpePerformance":`The X-axis represents 30-day realized volatility, while the Y-axis represents 30-day realized return.
      The position of each index in the chart represents its return/risk ratio (sharpe ratio).
       The blue dot is the most broad-based index available, representing the sharpe ratio of the overall market. 
       Any indices above the dotted line exhibit higher realized risk adjusted return than the overall market over the past 30 days.`
  }
  return(
    <Tooltip title={cats[cat] || ""}>
      <InfoOutlinedIcon sx={{fontSize:'14px'}}></InfoOutlinedIcon>
    </Tooltip>
  )
}

const fakeData = () => {
  var response = []
  const factors = ["size","value","quality","momentum","volatility"]
  const countries = ["US","UK","GR"]
  const sectors = ["",""]

  function genValues(){
    var values = []
    var prev = 1000
    for (let i = 0; i < 30; i++) {
      prev = prev*((Math.random()/2)+0.75)
      values.push({"time":i,"value":prev})
    }
    return(values)
  }
  function genIndex(name,tags){
    var values = genValues()
    var ret30 = (values[values.length-1].value/values[0].value)-1
    var vol30 = Math.random()
    return({
      "id":name,
      "version":0,
      "name":name,
      "data":values,
      "return":values[0].value/values[values.length-1].value,
      "tags":tags,
      "stats":{
        "returns":{
          "30d":ret30
        },
        "volatility":{
          "30d":vol30
        },
        "sharpe":{
          "30d":ret30/vol30
        }
      },  
      "volatility_30d":vol30,
      "return_30d":ret30
    })
  }

  //gen indices
  for (let i = 0; i < factors.length; i++) {
    response.push(genIndex(`low_${factors[i]}`,{"factor":factors[i],"asset_class":"equity"}))
    response.push(genIndex(`high_${factors[i]}`,{"factor":factors[i],"asset_class":"equity"}))
  }
  for (let i = 0; i < countries.length; i++) {
    response.push(genIndex(`${countries[i]}`,{"country":countries[i],"asset_class":"equity"}))
  }
  response.push(genIndex('global',{"country":"global","asset_class":"equity"}))
  return response
}

export default function IndicesNew(props) {
  const dispatch = useDispatch()
  const [tags,setTags] = useState({})
  const { data = [], isLoading, isFetching, isError } = useIndicesQuery({
    performance:true,
    increment:"eod",
    duration:"30d",
    attributes:"limited"
  })
  const [loading,setLoading] = useState(true)
  const [indices,setIndices] = useState({})
  const [filters,setFilters] = useState([])
  const [indicesFiltered,setIndicesFiltered] = useState({})
  const [refIndex,setRefIndex] = useState(null)
  const [indexOverviewPanelOpen,setIndexOverviewPanelOpen] = useState(false)
  const [indexSelected,setIndexSelected] = useState(undefined)
  const stateRef = useRef()
  stateRef.current = indicesFiltered

  const websocketConnect = () => {
    const allId = "00000000-0000-0000-0000-000000000000-0"
    console.log('connecting websocket')
    var ws = new WebSocket(`${config.wsUrl}?index_id=${allId}&table=values`);
    //setWebsocket(ws)
    ws.onopen = () => {
        console.log('websocket connected')
    }
    ws.onmessage = e => {
        const message = JSON.parse(e.data)
        //console.log(message)
        if(message.id){
          if(stateRef.current[message.id]){
            //var oldObj = JSON.parse(JSON.stringify(stateRef.current))
            //oldObj[message.id].value = message.value
            //setIndicesFiltered(oldObj)
            //setIndicesFiltered(state => (state[message.id].value = message.value, state))
            setIndicesFiltered((pre)=> ({...pre,[message.id]: {...pre[message.id], value: message.value}}));
            //setIndicesFiltered(prevData => ({...prevData,[message.id]:message.value}))
            //console.log("match")
          }
        }
        //updatePrice(message.value,message.time)
    }
    ws.onclose = e => { console.log('websocket closed')}
    ws.onerror = err => {
        console.error("Socket encountered error: ",err.message,"Closing socket")
        ws.close()
    }
  }

  useEffect(() => {(async()=>{
    dispatch({type:'SET_PAGE_TITLE',payload:'INDICES'})
    document.title = `Index One | Indices`
    //const response = fakeData()
    if(data.length == 0){
      return
    }
    const response = JSON.parse(JSON.stringify(data))
    console.log(response)
    var responseObject = {}
    var tags = {}
    var refIndex = null
    for (let i = 0; i < response.length; i++) {
      var idVersioned = `${response[i].id}-${response[i].version}`
      response[i].id = idVersioned
      //response[i].value = 1000
      responseObject[idVersioned] = response[i]
      if(response[i].id === config.landerIndex){
        refIndex = response[i].id
      }
      if(response[i].hasOwnProperty("tags")){
        //console.log(response[i].tags)
        for (const [key, value] of Object.entries(response[i].tags)) {
          if(!tags.hasOwnProperty(key)){
            tags[key] = []
          }
          if(Array.isArray(value)){
            tags[key].push(...value)
          }else{
            tags[key].push(value)
          }
        }
      }
    }
    for (const prop in tags) {
      tags[prop] = tags[prop].filter((item, index, arr) => arr.indexOf(item) === index);
    }
    if(refIndex === null){ refIndex = Object.keys(responseObject)[0] }
    setTags(tags)
    setIndices(responseObject)
    setIndicesFiltered(responseObject)
    setRefIndex(refIndex)
    //websocketConnect()
    setLoading(false)
  })()},[isLoading])

  function toggleIndexOverviewPanel(indexId){
    if(indexId){
      setIndexOverviewPanelOpen(true)
      setIndexSelected(indexId)
    } else {
      setIndexOverviewPanelOpen(false)
      setIndexSelected(undefined)
    }
  }

  return (
      <Container maxWidth={false} disableGutters style={{padding:'1rem'}}>
        <Grid container spacing={3}>
          {/*<Grid item xs={12} sm={3}>
            <Stack direction='column'>
            <Typography variant="body1Heavy">Sectors</Typography>
            <Paper>
              <MultiLine data={indices} refIndex={refIndex}/>
            </Paper>
            <Typography variant="body1Heavy">Factors</Typography>
            <Paper>
              <MultiLine data={indices} refIndex={refIndex}/>
            </Paper>
            </Stack>
          </Grid>*/}
          <Grid item xs={12} sm={4}>
            <Typography variant="body1Heavy">Absolute Performance </Typography>{returnTooltip("absolutePerformance")}
            <Paper>
              <Tornado data={indices} refIndex={refIndex}/>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="body1Heavy">Normalized Performance </Typography>{returnTooltip("normalizedPerformance")}
            <Paper>
              <MultiLine data={indices} refIndex={refIndex}/>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="body1Heavy">30-day Realized Risk & Return </Typography>{returnTooltip("sharpePerformance")}
            <Paper>
              <Scatter data={indices} refIndex={refIndex}/>
            </Paper>
          </Grid>
        </Grid>
        <Typography variant="body1Heavy">Index Screener</Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={2}>
            <FilterAccordion tags={tags} data={indices} filters={filters} setFilters={setFilters} setData={setIndicesFiltered}/>
          </Grid>
          <Grid item xs={12} sm={indexOverviewPanelOpen ? 5 : 10}>
            {filters.length > 0 &&
              <Grid container spacing={1}>
                {filters.map((f)=>{
                  var spl = f.split(":")
                  return(<Grid item sx={{marginBottom:'1em'}}>
                    <Chip label={`${mapping[spl[0]] || snakeToTitleCase(spl[0])}: ${mapping[spl[1]] || snakeToTitleCase(spl[1])}`} onDelete={e=>setFilters(filters.filter(item=>item !== f))} />
                  </Grid>)
                })}
              </Grid>
            }
            <Paper sx={{height:'60vh',margin:'0px',padding:'0px'}}><IndicesTable loading={loading} data={indicesFiltered} toggleIndexOverviewPanel={toggleIndexOverviewPanel}/></Paper>
          </Grid>
          {indexOverviewPanelOpen &&
            <Grid item xs={12} sm={5}>
              <Paper><IndexOverviewPanel indexId={indexSelected} indexData={indices[indexSelected]} toggleIndexOverviewPanel={toggleIndexOverviewPanel}/>  </Paper>
            </Grid>
          }
        </Grid>
      </Container>
  );
}