const dev = {
  baseUrl: "https://ysf0rwxyr2.execute-api.eu-west-1.amazonaws.com/dev/",
  wsUrl: "wss://ruljowd0z4.execute-api.eu-west-1.amazonaws.com/dev/",
  landerIndex:'d29372cd-f8f7-4720-84bd-7840cc2e12b1-0',
  apiKey:"mYdox61yv4amm60b5rnXrbgW30OXZ5O446qR9Wt4",
  stripeKey:"pk_test_m2mv3LgWF9x3tv0c9HF0s6Y4"
};

const sandbox = {
  baseUrl: "https://ysf0rwxyr2.execute-api.eu-west-1.amazonaws.com/dev/",
  wsUrl: "wss://ruljowd0z4.execute-api.eu-west-1.amazonaws.com/dev/",
  landerIndex:'d29372cd-f8f7-4720-84bd-7840cc2e12b1-0',
  apiKey:"mYdox61yv4amm60b5rnXrbgW30OXZ5O446qR9Wt4",
  stripeKey:"pk_test_m2mv3LgWF9x3tv0c9HF0s6Y4"
};
  
const prod = {
  baseUrl: "https://seqe82gir9.execute-api.eu-west-1.amazonaws.com/prod/",
  wsUrl: "wss://846dfj97pc.execute-api.eu-west-1.amazonaws.com/prod/",
  landerIndex:'d1d00477-8247-4225-98b4-9aa9db7330c7-0',
  apiKey:"mYdox61yv4amm60b5rnXrbgW30OXZ5O446qR9Wt4",
  stripeKey:"pk_live_jb09fGtpjN9UDAUmIlCdyRDJ"
};


function setConfig(stage){
  if(stage === 'prod'){
    return prod
  } else if (stage === 'sandbox'){
    return sandbox
  } else {
    return prod //DEFAULT CASE MUST BE DEV WHEN DEPLOYING, YOU CAN USE PROD FOR TESTING
  }
}

const config = setConfig(process.env.REACT_APP_STAGE)

export default config

//comment