import { createSlice } from '@reduxjs/toolkit'
import { indexOneApi } from './indexOneApi'



const initialState = {
    message : "default message",
    severity : "success",
    isOpen : false,
    flip: false
}

export const notifierSlice = createSlice({
  name: 'notifier',
  initialState,
  reducers: {
    createNotification: (state,action) => {
      //alert(JSON.stringify(action))
      state.isOpen = true
      state.flip = !state.flip
      state.message = action.payload.message
      state.severity = action.payload.severity
    }
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      // matcher can be defined inline as a type predicate function
      (action) => true,
      (state, action) => {
        //console.log(action)
        try{
          //action.payload.status
          //action.payload.data.message
          state.message = `${action.payload.status} : ${action.payload.data.message}`
          state.isOpen = true
          state.flip = !state.flip
        }catch{
          try{
            state.message = `${action.type}`
            state.isOpen = true
            state.flip = !state.flip
          }catch{

          }
        }
        //state[action.meta.requestId] = 'rejected'
      }
    )
  }
})

// Action creators are generated for each case reducer function
export const {
  createNotification
} = notifierSlice.actions

export default notifierSlice.reducer