import React, { useEffect, useState } from 'react';
import { useStore, useDispatch } from 'react-redux'
import { useNavigate, useParams } from "react-router-dom";
import { useIndexEventCreateMutation } from '../../../store/indexOneApi';

//formatting
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack'
import Grid from '@mui/material/Grid'
import Divider from '@mui/material/Divider'
import FileUpload from '../../elements/FileUpload'

import { set, get, unset, compact, pickBy, identity, isUndefined } from 'lodash/fp';

//inputs
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Alert from '@mui/material/Alert';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button'
import LoadingButton from '@mui/lab/LoadingButton';
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'

//icons
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import FileUploadIcon from '@mui/icons-material/FileUpload';

//search
import SecuritySearch from '../../elements/indexwizard/SecuritySearch';

import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { Typography } from '@mui/material';


const tomorrowDateTime = new Date(Date.now() + 24 * 60 * 60 * 1000).toISOString().slice(0, 10) + ' 16:00:00';
const todayDateTime = new Date(Date.now()).toISOString().slice(0, 10) + ' 16:00:00';

export default function IndexEventCreate(props) {
  const params = useParams()
  const defaultEvent = {
    id:params.id,
    events:['divisor','reconstitution','rebalancing'],
    time:tomorrowDateTime
  }

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [loading,setLoading] = useState(false)
  const [indexEvent,setIndexEvent] = useState(defaultEvent)
  const [indexWeighting,setIndexWeighting] = useState([{'id':'','weight':0}])
  const [overrideConst,setOverrideConst] = useState(true)
  const [restatement,setRestatement] = useState(false)
  const [weightingRow,setWeightingRow] = useState(0)
  const [createTrigger] = useIndexEventCreateMutation()

  //new search
  const [securitySearchShow,setSecuritySearchShow] = useState(false)
  const [searchValue,setSearchValue] = useState('')

  const [errors, setErrors] = useState({})
  useEffect(() => {(async()=>{  
    dispatch({type:'SET_PAGE_TITLE',payload:'New Index Event'})
    document.title = `Index One | Index Event Create`
  })()},[])

  const handleSubmit = async () => {
    setLoading(true)
    var body = indexEvent
    if(overrideConst && indexWeighting.length > 0){
      if(indexEvent.events.includes('rebalancing')){
        body['weighting_unadjusted'] = Object.fromEntries( indexWeighting.map( x => [x.id, x.weight]) )
      }
      if(indexEvent.events.includes('reconstitution')){
        body['universe'] = indexWeighting.map(function (x) {return x.id})
      }
    }
    if(restatement){
      body.restatement = true
    }
    console.log(body)
    setIndexEvent(body)
    const response = await createTrigger(body).unwrap()
    if(response.error){
      console.log(response)
      dispatch({type:'SNACKBAR',payload:{open:true,message:'Event Creation Failed'}})
      setLoading(false)
    } else {
      console.log(response)
      dispatch({type:'SNACKBAR',payload:{open:true,message:'Event Created Successfully'}})
      setLoading(false)
      navigate(`../track`)
    }
  }

  const setDate = (e) => {
    e.persist()
    var newVal = e.target.value.replace('T', ' ')
    newVal = newVal.concat(':00')
    console.log(newVal)
    if(newVal < todayDateTime){
      setRestatement(true)
    }
    handleChange(indexEvent,setIndexEvent,e.target.id,newVal)
  }

  const handleChange = (state, setFunc, path, value, noValidate) => {
    //if(!isNaN(value)){ value = parseFloat(value) }
    let prevState = state
    let newState = set(path, value, prevState)
    setFunc(newState)
  }

  const handleToggle = (id,checked) => {
    let prevArray = indexEvent.events
    var prevSet = new Set(prevArray)
    if(checked){
      prevSet.add(id)
    } else{
      prevSet.delete(id)
    }
    let newArray = Array.from(prevSet)
    handleChange(indexEvent,setIndexEvent,'events',newArray)
    console.log(newArray)
  }

  const handleRemove = (state,setFunc,path) => {
    console.log(path)
    let prevState = state
    let newState = unset(path, prevState)
    console.log({ ...newState })
    if (Number.isInteger(path[path.length - 1])) {
      newState = set(path.slice(0, -1), compact(get(path.slice(0, -1), newState)), newState)
    } else {
      // newState = set(path.slice(0,-1),pickBy(get(path.slice(0,-1),newState),function(val){ return isUndefined(val) }),newState)
    }
    console.log(newState)
    setFunc(newState)
  }

  const timeSwitch = (e)=>{
    if(indexEvent.time){
      setIndexEvent(({ "time": _, ...rest }) => rest)
    } else {
      
      setIndexEvent({ ...indexEvent, time: tomorrowDateTime })
    }

  }
  const returnTextField = (path, label, rows, type) => {
    return (
      <TextField
        fullWidth
        id={JSON.stringify(path)}
        name={JSON.stringify(path)}
        label={label}
        multiline={rows ? true : false}
        rows={rows}
        type={type}
        value={get(path, indexEvent) || ''}
        error={get(path, errors)}
        helperText={get(path, errors)}
        variant="outlined"
        onChange={e => handleChange(path, e.target.value)}
      />
    )
  }

  const setSecurity = (id) => {
    handleChange(indexWeighting,setIndexWeighting,`${weightingRow}.id`,id)
    setSecuritySearchShow(false)
  }

  const returnSecuritySearchDialog = () => {
    return(
      <Dialog sx={{padding:'1em'}} open={securitySearchShow} onClose={e=>setSecuritySearchShow(false)}>
        <SecuritySearch
          setShow={setSecuritySearchShow}
          show={securitySearchShow}
          selected={[]}
          selectFunction={e => setSecurity(e[1])}
        />
      </Dialog>
    )}

  return (
    <Container maxWidth='md' disableGutters sx={{padding:'2em'}}>
      <Alert severity="warning">
        As of October 28th 2024, we will migrate to a new symbol mapping based on Bloomberg/FIGI composite symbols. Both legacy and new mappings can be used up until this date.
        The new symbol mapping can be accessed <a href="https://indexone.gitbook.io/docs/product-guides/reference-data/symbol-mapping">here</a> 
      </Alert>
      {returnSecuritySearchDialog()}
      <Stack spacing={2}>
        <Typography variant='h4'>Event Scheduling</Typography>
        <Stack direction={'row'}>
        <FormControlLabel control={<Switch checked={!indexEvent.time} onChange={e=>timeSwitch(e)}/>} label='Run Now'></FormControlLabel>
        <TextField
          InputLabelProps={{ shrink: true }}
          disabled={!indexEvent.time}
          fullWidth
          id="time"
          label="Time"
          type="datetime-local"
          format="yyyy/MM/dd hh:mm"
          value={get("time", indexEvent) ? get("time", indexEvent).replace(' ', 'T') : undefined}
          onChange={e => setDate(e)}
        />
        </Stack>
        {!indexEvent.time &&
          <Alert severity="info">
            The index event is set to run immediately using live prices. Untick the "Run Now" toggle if you want to schedule the event for another time instead.
          </Alert>
        }

      <Divider/>
        <Stack direction="row">
          <FormControlLabel control={<Switch checked={indexEvent.events.includes('reconstitution')} onChange={e=>handleToggle('reconstitution',e.target.checked)}/>} label='Reconstitution'></FormControlLabel>
          <FormControlLabel control={<Switch checked={indexEvent.events.includes('rebalancing')} onChange={e=>handleToggle('rebalancing',e.target.checked)}/>} label='Rebalancing'></FormControlLabel>
        </Stack>
      <Divider/>
      <Stack direction='row' justifyContent='space-between'>
        <FormControlLabel control={<Switch checked={overrideConst} onChange={e=>setOverrideConst(e.target.checked)}/>} label='Override Constituents'></FormControlLabel>
        <FormControlLabel control={<Switch checked={restatement} onChange={e=>setRestatement(e.target.checked)}/>} label='Restatement'></FormControlLabel>
        <FileUpload
          returnData={setIndexWeighting}
          fileType='weights_event'
          style='button'
          disabled={!overrideConst}
        />
      </Stack>
        {restatement &&
          <Alert severity='info'>If the event time is in the past, this will overwrite all index values and events between the event time and now.</Alert>
        }
        {!overrideConst &&
          <Alert severity="error">You are not specifying any weights for this rebalance, this will generate a rebalance with default weightings.</Alert>
        }
        {overrideConst &&
          <>
           {indexWeighting.map((item, idx) =>
          <Stack direction="row" spacing={1}>
            <TextField 
              onChange={e => handleChange(indexWeighting,setIndexWeighting,`${idx}.id`,e.target.value)}
              value={item.id}
              InputProps={{
                endAdornment: <InputAdornment position="end">
                  <IconButton onClick={e=>{setWeightingRow(idx);setSecuritySearchShow(true)}}>
                    <SearchIcon/>
                  </IconButton>
                </InputAdornment>,
              }}
            />
            { indexEvent.events.includes('rebalancing') &&
            <TextField
              value={item.weight}
              onChange={e => handleChange(indexWeighting,setIndexWeighting,`${idx}.weight`,e.target.value)}
              InputProps={{
                endAdornment: <InputAdornment position="end">decimal</InputAdornment>,
              }}
            />
            }
            <IconButton color='secondary' size='small' aria-label="delete" onClick={e=>setIndexWeighting(indexWeighting.filter((asd, i2) => i2 !== idx))}>
              <CloseIcon />
            </IconButton>
          </Stack>
            )}
          <Button variant='outlined' aria-label="add" onClick={e=>setIndexWeighting(prev => [...prev, {'id':'','weight':0}])}>+</Button>
          </>
        }
      <Divider/>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={2}
      >
        <LoadingButton loading={loading} onClick={e=> handleSubmit()} size='large' variant='contained' fullWidth>Save</LoadingButton>
        <LoadingButton onClick={e=> navigate(`../track`)} size='large' variant='outlined' color='secondary' fullWidth>Discard</LoadingButton>
      </Stack>
      </Stack>
    </Container>
  );
}